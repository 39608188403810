(function(){
	var jaCalculouSamerda = false;

	function getGraficPosition(){
		$('.bar[data-height]').each(function(){
			var rect = this.getBoundingClientRect();
			var height = $(this).data('height');

			if(rect.top < window.innerHeight + 100){
				$(this).css('height', height);
			}
		});
	}


	getGraficPosition();

	$(window).scroll(function(){
		if(jaCalculouSamerda) return;


		setTimeout(function(){
			jaCalculouSamerda = false;
		}, 100);

		getGraficPosition();
	});
})();