$('[data-toggle="slideTo"]').click(function(){
	event.preventDefault();

	var target = $(this).attr('data-target');

	var position = $(target).offset().top;

	$('body, html').animate({
		scrollTop: position
	}, 2000);


});