var popUpIndex = $('.pop-up');

var botaoPopUpIndex = $('.btn-popup, .pop-up');

window.setTimeout(function(){
	popUpIndex.addClass('block');
}, 0);

botaoPopUpIndex.click(function(){
	popUpIndex.removeClass('block');
});