function verifyMenu(){
	var conditionTamanhoDaTela = $(window).width() > 992;

	if(conditionTamanhoDaTela){

		var condicaoParaFixar = $(window).scrollTop();

		if(condicaoParaFixar >= 145){
			$('#menu').addClass('menu-fixo');
		}else{
			$('#menu').removeClass('menu-fixo');
		}
	}
}

verifyMenu();

$(window).scroll(function(){
	verifyMenu();
});