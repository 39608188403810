function verificarMenu(){
	var conditionTamanhoDaTelaXs = $(window).width() < 768;

	if(conditionTamanhoDaTelaXs){

		var condicaoParaFixarXs = $(window).scrollTop();

		if(condicaoParaFixarXs >= 86){
			$('.nav-bar').addClass('menu-fixo');
		}else{
			$('.nav-bar').removeClass('menu-fixo');
		}
	}
}

verificarMenu();

$(window).scroll(function(){
	verificarMenu();
});