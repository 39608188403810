function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#produtos-carousel',{
    loop:true,
    nav:true,
    navText: [
        '<span class="control-banner pull-left icon flecha-banner-left-cinza"></span>',
        '<span class="control-banner pull-right icon flecha-banner-right-cinza"></span>'
    ],
    autoplay:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2,
            margin: 20
        },
        1170:{
            items:3,
            margin:10,
        }
    }
})